import getCookie from '../utils/cookies';
import fetchHelper from './fetchHelper';

const validateToken = async () => {
    const token = getCookie('auth');
    if (!token) {
        return { valid: false };
    }
    const res = await fetchHelper('/auth/validate');

    return res;
}

export default validateToken;
import React from 'react';
import styles from './LoadingIndicator.module.css';

const LoadingIndiactor = ({ sizeInPixels = '16px' }) => (
    <div className={styles.container} style={{ fontSize: sizeInPixels }}>
        <div className={styles.block}>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
        </div>
    </div>
)

export default LoadingIndiactor;
import styles from './Toast.module.scss';

const Toast = ({ type = 'error', title, description }) => {
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.container} ${styles[type]}`}>
        <div className={styles.iconWrapper}>
          <i class="fa fa-warning" />
        </div>
        <div className={styles.body}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default Toast;
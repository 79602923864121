import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { deleteCookie } from '../../utils/cookies';
import { useGlobalStateContext } from '../../GlobalStateContext';
import styles from './UserSettings.module.scss';

const UserSettings = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const avatarRef = useRef();
    const { state, dispatch } = useGlobalStateContext();
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const handleClickBody = (event) => {
        if (event.target !== avatarRef?.current && event.target?.parentNode !== avatarRef?.current) {
            setIsMenuOpen(false);
        }
    }

    const toggleDarkMode = () => {
        dispatch({ type: 'TOGGLE_DARK_MODE' });
    }

    const signOut = () => {
        deleteCookie('auth');
        return navigate('/login');
    }

    useEffect(() => {
        document.body.addEventListener('click', handleClickBody);

        return () => {
            window.removeEventListener('click', handleClickBody);
        }
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.avatar} onClick={toggleMenu} ref={avatarRef}>
                <i className="fa fa-user" />
            </div>
            {isMenuOpen && (
                <div className={styles.dropDownContainer}>
                    <div className={styles.option} onClick={toggleDarkMode}>
                        <i className={`fa fa-toggle-${state.isDarkMode ? 'on' : 'off'}`} />
                        <span>Dark Mode</span>
                    </div>
                    <div className={styles.option} onClick={signOut}>
                        <i className="fa fa-door-open" />
                        <span>Sign Out</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default UserSettings;
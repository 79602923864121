import { useEffect, useState } from 'react';
import getTrialusers from '../../api/getTrialUsers';
import getUsageData from '../../api/getUsageData';
import LoadingIndiactor from '../../components/LoadingIndicator/LoadingIndicator';
import styles from './TrialStats.module.scss';


const TrialStats = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [usageData, setUsageData] = useState({});

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const usersResponse = await getTrialusers();
      const usageDataResponse = await getUsageData();
      setIsLoading(false);
      if (usersResponse.Items) {
        const sortedItems = usersResponse.Items.sort((user1, user2) => new Date(user2.trial?.M.trialStartDate?.S) - new Date(user1.trial?.M.trialStartDate?.S))
        setUsers(sortedItems);
        setUsageData(usageDataResponse);
      }
    })();
  }, []);

  return (
    <div className={styles.container}>
      {isLoading && <LoadingIndiactor />}
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Is Trial User?</th>
            <th>Start Date</th>
            <th>Duration</th>
            <th>Is Deactivated</th>
            <th>Converted To Paying?</th>
            <th>Usage Data</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr>
              <td><b>{user.email.S}</b></td>
              <td>{user.trial?.M ? 'Yes' : 'No'}</td>
              <td>{user.trial?.M?.trialStartDate?.S ? new Date(user.trial.M.trialStartDate.S).toString().split(' ').splice(1, 4).join(' ') : ''}</td>
              <td>{user.trial?.M ? user.trial.M.trialDurationDays?.N : ''}</td>
              <td>{user.deactivated?.BOOL ? 'Yes' : 'No'}</td>
              <td><b>{user.trialConverted?.BOOL ? 'Yes' : 'No'}</b></td>
              <td>{usageData[user.email.S]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TrialStats;
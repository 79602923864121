import { useState } from 'react';
import { Link } from 'react-router-dom';
import createTrialUser from '../../api/createTrialUser';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import styles from './TrialSignup.module.scss';

const TrialSignupPage = () => {
  const [email, setEmail] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async(e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsLoading(true);

    const response = await createTrialUser({ email, agreedToTerms });
    setIsLoading(false);

    if (response.error) {
      setErrorMessage(response.message);
    } else {
      setSuccess(true);
    }
  }

  return (
    <div className={styles.container}>
      <Link to={'/'}>
        <img src="/logo.png" alt="Logo" className={styles.logo} />
      </Link>
      <div className={styles.imageContainer}>
        <img src="/chatbot-devices.png" alt="chatbot-devices" />
      </div>
      <div className={styles.formContainer}>
        {!success && (
          <form className={styles.form} onSubmit={handleSubmit}>
            <h1 className={styles.title}>FREE 7 Day Trial</h1>
            <p className={styles.bodyText}>Your Game Development Questions, Answered 24/7</p>
            {!!errorMessage && (
                <div className={styles.errorMessage}>{errorMessage}</div>
            )}
            <Input
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Email"
                className={styles.input}
                autoComplete="email"
                required
            />
            <div className={styles.checkboxContainer}>
              <input id="terms" type="checkbox" checked={agreedToTerms} onChange={e => setAgreedToTerms(e.target.checked)} required/>
              <label className={styles.checkboxLabel} for="terms">
                I agree to the <a href="/terms.html" target="_blank">Terms of Service</a> and <a href="/privacy.html" target="_blank">Privacy Policy</a>
              </label>
            </div>
            <Button type="submit" fullWidth>
                {isLoading && <div className={styles.loadingWrapper}><LoadingIndicator /> </div>}
                Sign up
            </Button>
            
          </form>
        )}
        {success && (
          <div>
            <h1 className={styles.title}>Trial account created successfully 🎉</h1>
            <p className={styles.bodyText}>An email with your login info has been sent to <b>{email}</b></p>
            <Link to={'/login'}>
              <Button fullWidth>Go To Login Page</Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default TrialSignupPage;
import fetchHelper from "./fetchHelper";

const login = async ({ email, password }) => {
    const res = await fetchHelper(`/auth/login`, {
        method: "POST",
        body: JSON.stringify({
            email,
            password,
        })
    });

    return res;
}

export default login;
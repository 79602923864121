import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import styles from './Message.module.scss';
import Markdown from 'react-markdown'

const Message = ({ message = {}, isLoading = false }) => {
    const { role, content, links } = message;
    const botLogoUrl = '/logo.png';

    return (
        <div className={`${styles.container} ${styles[role]}`}>
            <div className={styles.avatar}>
                {role === 'assistant' && (
                    <div style={{ backgroundImage: `url(${botLogoUrl})` }} className={styles.avatarBG} />
                )
                }
            </div>
            <div className={styles.messageBubble}>
                {isLoading ? (
                    <LoadingIndicator sizeInPixels='20px' />
                ) : (
                    <div>
                        <Markdown>
                            {content}
                        </Markdown>
                    </div>
                )}

            </div>
        </div>
    )
}

export default Message;
import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styles from './Login.module.scss';
import login from '../../api/login';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { setCookie } from '../../utils/cookies';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';

const Login = () => {
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState(searchParams.get('email') || '');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setIsLoading(true);

        const response = await login({ email, password });
        setIsLoading(false);

        if (response.token) {
            setCookie('auth', response.token);
            const redirectUrl = searchParams.get('redirect');
            if (redirectUrl) {
                return navigate(decodeURIComponent(redirectUrl));
            }
            return navigate('/chat/new');
        }

        setErrorMessage(response.message);
    }

    const handleInputChange = (e) => {
        const { type, value } = e.target;

        if (type === 'email') {
            setEmail(value?.toLowerCase() || '');
        } else {
            setPassword(value);
        }
    }

    return (
        <form className={styles.container} onSubmit={handleSubmit}>
            <Link to={'/trial-signup'}>
                <img src="/logo.png" alt="Logo" className={styles.logo} />
            </Link>
            <h1 className={styles.title}>Login</h1>
            {!!errorMessage && (
                <div className={styles.errorMessage}>{errorMessage}</div>
            )}
            <Input
                type="email"
                value={email}
                onChange={handleInputChange}
                placeholder="Email"
                className={styles.input}
                autoComplete="email"
            />
            <Input
                type="password"
                value={password}
                onChange={handleInputChange}
                placeholder="Password"
                className={styles.input}
                autoComplete="current-password"
            />
            <Button type="submit" fullWidth>
                {isLoading && <div className={styles.loadingWrapper}><LoadingIndicator /> </div>}
                Submit
            </Button>
            <Link to='/trial-signup' className={styles.signupLink}>No account? Sign up for a free trial</Link>
        </form>
    )
}

export default Login;
import fetchHelper from './fetchHelper';

const askQuestion = async ({ question, chatHistory, videoId }) => {
    const res = await fetchHelper(`/chat/ask`, {
        method: "POST",
        body: JSON.stringify({
            question,
            chatHistory,
            videoId
        })
    });

    return res;
}

export default askQuestion;
import { useRef } from 'react';
import styles from './Popup.module.scss';


const Popup = ({ children, onClose }) => {
  const containerRef = useRef();

  const handleClick = (e) => {
    if(e.target === containerRef.current) {
      onClose();
    }
  };

  return (
    <div className={styles.container} onClick={handleClick} ref={containerRef}>
      <div className={styles.popupContainer}>
        <div className={styles.header}>
          <div className={styles.closeIcon} onClick={() => onClose()}><i className="fa fa-close" /></div>
        </div>
        <div className={styles.body}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Popup;
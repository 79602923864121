import { getCookie } from '../utils/cookies';
export const decode = (token) => {
  if (!token) return {};
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const validateAdmin = () => {
  const jwtToken = getCookie('auth');
  const { email = '' } = decode(jwtToken) || {};
  return email === 'ali.elzoheiry@gmail.com';
}

export default decode;
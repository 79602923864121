import getCookie from '../../utils/cookies';
import styles from './ChatEmptyState.module.scss';
import { decode } from '../../utils/jwt';

const ChatEmptyState = ({ videoTitle }) => {
    const botLogoUrl = '/logo.png';
    const jwtToken = getCookie('auth');
    const { email = '' } = decode(jwtToken) || {};
    const name = email.split('@')[0];

    return (
        <div className={styles.container}>
            <div style={{ backgroundImage: `url(${botLogoUrl})` }} className={styles.logo} />
            <div className={styles.text}>
                {videoTitle ? (
                    <span>What would you like to know about<br/><b>"{videoTitle}"</b></span>
                ) : (
                    <span>Hello {name},<br />I see you have a general question. How can I help?</span>
                )}
            </div>
        </div>
    )
}

export default ChatEmptyState;
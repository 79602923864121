import { useEffect, useReducer } from 'react';
import GlobalStateContext from './GlobalStateContext.js';
import './App.scss';
import './Normalize.css';
import Chat from './pages/Chat/Chat.jsx';
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import Login from './pages/Login/Login.jsx';
import Usage from './pages/Usage/Usage.jsx';
import { validateToken } from './api';
import { validateAdmin } from './utils/jwt';
import localStorage from './utils/localStorage.js';
import TrialSignupPage from './pages/TrialSignup/TrialSignup.jsx';
import TrialStats from './pages/TrialStats/TrialStats.jsx';

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_DARK_MODE': {
      return {
        ...state,
        isDarkMode: !state.isDarkMode
      }
    }
    default: return state;
  }
}

const App = () => {
  const validateAuthCookie = async () => {
    const response = await validateToken();
    return response?.valid
  }
  const defaultDarkMode = localStorage.itemExists('isDarkMode') ? Boolean(localStorage.getItem('isDarkMode')) : true;

  const [state, dispatch] = useReducer(reducer, { isDarkMode: defaultDarkMode });

  useEffect(() => {
    localStorage.setItem('isDarkMode', state.isDarkMode);
  }, [state.isDarkMode]);

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      <div className={`App ${state.isDarkMode ? 'theme-dark' : ''}`}>
        <RouterProvider router={createBrowserRouter([
          {
            path: "/",
            loader: async () => {
              if (await validateAuthCookie()) {
                return redirect('/chat/new');
              }

              return redirect('/login');
            },
            element: <div>Loading....</div>
          },
          {
            path: "/chat/new",
            element: <Chat isNewChat />,
            loader: async () => {
              if (!(await validateAuthCookie())) {
                return redirect('/login');
              }
              return null;
            }
          },
          {
            path: "/chat/:videoId?",
            element: <Chat />,
            loader: async ({ params }) => {
              if (!(await validateAuthCookie())) {
                const redirectUrl = encodeURIComponent(`/chat/${params.videoId}`);
                return redirect(`/login?redirect=${redirectUrl}`);
              }
              return null;
            }
          },
          {
            path: "/login",
            element: <Login />,
            loader: async () => {
              if (await validateAuthCookie()) {
                return redirect('/chat/new');
              }
              return null;
            }
          },
          {
            path: "/usage",
            element: <Usage />,
            loader: async () => {
              if (!(await validateAuthCookie())) {
                const redirectUrl = encodeURIComponent('/usage');
                return redirect(`/login?redirect=${redirectUrl}`);
              }

              if (!(await validateAdmin())) {
                return redirect('/chat/new');
              }
              return null;
            }
          },
          {
            path: "/trial-stats",
            element: <TrialStats />,
            loader: async () => {
              if (!(await validateAuthCookie())) {
                const redirectUrl = encodeURIComponent('/usage');
                return redirect(`/login?redirect=${redirectUrl}`);
              }

              if (!(await validateAdmin())) {
                return redirect('/chat/new');
              }
              return null;
            }
          },
          {
            path: "/trial-signup",
            element: <TrialSignupPage />,
            loader: async () => {
              if (await validateAuthCookie()) {
                return redirect('/chat/new');
              }
              return null;
            }
          },
        ])} />
      </div >
    </GlobalStateContext.Provider>
  );
}

export default App;

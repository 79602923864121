export const getCookie = (cookieName) => {
    const cookie = document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`);
    return cookie ? cookie.pop() : '';
};

export const deleteCookie = (cookieName) => {
    document.cookie = cookieName + '=; Max-Age=0'
}

export const setCookie = (cookieName, cookieValue) => {
    document.cookie = `${cookieName}=${cookieValue}`;
}

export default getCookie;
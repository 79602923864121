// type: json | string
export const getItem = (key, { type = 'json' } = {}) => {
    const item = localStorage.getItem(key);
    if (!item) {
        return '';
    }
    if (type === 'json') {
        return JSON.parse(item);
    }
    return item;
}

// type: json | string
export const setItem = (key, value, { type = 'json' } = {}) => {
    const formattedValue = type === 'json' ? JSON.stringify(value) : String(value);
    localStorage.setItem(key, formattedValue);
}

export const removeItem = (key) => {
    localStorage.removeItem(key);
}

export const itemExists = (key) => {
    const item = localStorage.getItem(key);
    return !!item;
}

export default { getItem, setItem, removeItem, itemExists };
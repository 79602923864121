import { getCookie } from '../utils/cookies';

const fetchHelper = async (url, options = {}) => {
    const token = getCookie('auth');
    const urlPrefix = process.env.REACT_APP_RUNNING_INDEPENDENTLY === 'true' ? 'http://localhost:3000' : '/api';
    const cleanUrl = url.charAt(0) === '/' ? url.slice(1) : url;
    const res = await fetch(`${urlPrefix}/${cleanUrl}`, {
        ...options,
        headers: {
            'Content-Type': 'application/json',
            ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
            ...(options.headers || {}),
        }
    });

    return res.json();
}

export default fetchHelper;
import fetchHelper from "./fetchHelper";

const createTrialUser = async ({ email, agreedToTerms }) => {
    const res = await fetchHelper('/users/new-trial-user', {
        method: "POST",
        body: JSON.stringify({
            email,
            agreedToTerms,
        })
    });

    return res;
}

export default createTrialUser;
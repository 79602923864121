import styles from './QuestionField.module.scss';
import { useState } from 'react';
import Button from '../Button/Button';
import Input from '../Input/Input';

const QuestionField = ({ onSendQuestion, onClearChatHistory, disabled = false, disableSubmit = false }) => {
    const [questionText, setQuestionText] = useState('');

    const handleChange = (e) => {
        setQuestionText(e.target.value);
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if (!questionText.trim()) {
            return;
        }

        setQuestionText('');
        onSendQuestion(questionText);
    }

    return (
        <form onSubmit={handleSubmitForm} className={styles.container}>
            <div className={styles.inputWrapper}>
                <Input
                    type="text"
                    value={questionText}
                    onChange={handleChange}
                    placeholder="Ask a question..."
                    disabled={disabled}
                    maxLength={2000}
                />
            </div>
            
            <div className={styles.buttonWrapper}>
                <Button type="submit" disabled={disabled || disableSubmit}>
                    <div className={styles.desktop}>Send</div>
                    <div className={styles.mobile}><i className="fa fa-paper-plane"></i></div>
                </Button>
            </div>
            <div className={styles.buttonWrapper}>
                <Button secondary onClick={onClearChatHistory} disabled={disabled || disableSubmit}>
                    <div className={styles.desktop}>Clear Chat</div>
                    <div className={styles.mobile}><i className="fa fa-trash"></i></div>
                </Button>
            </div>
        </form>
    )
}

export default QuestionField;
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from '../Button/Button';
import styles from './Sidebar.module.scss';

const Sidebar = ({ chatHistoryStorage = {}, detailsByVideo = {}, isLoadingMessage = false }) => {
  const { videoId = 'General' } = useParams();
  const chatKeys = Object.keys(chatHistoryStorage).filter(key => !!chatHistoryStorage[key]?.length).reverse();
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 768);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <>
      <div className={`${styles.collapseButton} ${isCollapsed ? styles.isCollapsed : ''}`} onClick={toggleCollapse}>
        {isCollapsed
          ? <i className="fa fa-caret-right" />
          : <i className="fa fa-caret-left" />
        }
      </div>

      <div className={`${styles.mobileOverlay} ${isCollapsed ? styles.isCollapsed : ''}`} onClick={toggleCollapse} />
      <div className={`${styles.container} ${isCollapsed ? styles.isCollapsed : ''} ${isLoadingMessage ? styles.isDisabled : ''}`}>
        <Link to={'/chat/new'}>
          <Button fullWidth><i className="fa fa-edit" /> New Chat</Button>
        </Link>
        <div className={styles.chatHistoryContainer}>
          <h3 className={styles.chatHistoryTitle}>Chats History</h3>
          {chatKeys.map(chatKey => {
            const details = detailsByVideo[chatKey]?.snippet;
            return (
              <Link
                key={`chat-${chatKey}`}
                className={`${styles.chatLink} ${videoId === chatKey ? styles.selected : ''}`}
                to={chatKey === 'General' ? '/chat' : `/chat/${chatKey}`}
              >
                {chatKey !== 'General' && <img className={styles.background} src={details?.thumbnails.standard.url} alt={details?.title} title={details?.title} />}
                {chatKey === 'General' && <div className={`${styles.generalChatBlock} ${styles.background}`} style={{ backgroundImage: 'url(/general-bg.png)' }} />}
              </Link>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Sidebar;
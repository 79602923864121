import { useEffect, useState } from 'react';
import { fetchUsage } from "../../api";
import localStorage from '../../utils/localStorage';
import styles from './Usage.module.scss';

const Usage = () => {
    const [usageData, setUsageData] = useState({});
    const [oldUsageData, setOldUsageData] = useState({});
    const highestValue = Math.max(...Object.values(usageData));


    useEffect(() => {
        (async () => {
            const response = await fetchUsage();
            if (response && !response.error) {
                delete response.usageData['ali.elzoheiry@gmail.com'];
                setUsageData(response.usageData);
                setOldUsageData(localStorage.getItem('usageData') || {});
                localStorage.setItem('usageData', response.usageData);
            }
        })()
    }, []);

    return (
        <div className={styles.container}>
            {Object.keys(usageData).sort((a, b) => usageData[b] - usageData[a]).map((email) => (
                <div key={email} className={styles.row}>
                    <div className={styles.email} title={email}>{email.split('@')[0]}</div>
                    <div className={styles.barWrapper}>
                        <div className={styles.bar} style={{ width: `${usageData[email] / highestValue * 100}%` }}></div>
                        <div className={styles.value}>
                            <span>{oldUsageData[email] || usageData[email]}</span>
                            {usageData[email] !== oldUsageData[email] && (
                                <span> + {Number(usageData[email]) - (Number(oldUsageData[email]) || 0)}</span>
                            )}
                            <span></span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Usage;
import Message from '../Message/Message';
import styles from './MessagesList.module.scss';

const MessagesList = ({ messages = [], isLoading = false }) => {
    return (
        <>
            {messages.map((message, index) => (
                <div className={styles.container} key={`message-${index}`}>
                    <Message message={message} />
                </div>
            ))}
            {isLoading && (
                <div className={styles.container}>
                    <Message message={{ role: 'assistant', content: '' }} isLoading={true} />
                </div>
            )}
        </>
    )
}

export default MessagesList;
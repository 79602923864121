import styles from './Button.module.scss';

const Button = ({ children, secondary = false, fullWidth = false, ...props }) => {
  return (
    <button
      className={`${styles.button} ${secondary ? styles.buttonSecondary : styles.buttonPrimary} ${fullWidth ? styles.fullWidth : ''}`}
      {...props}
    >
      {children}
    </button>
  )
};

export default Button;
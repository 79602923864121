import { useLocation, useSearchParams } from 'react-router-dom';
import Message from '../Message/Message';
import styles from './NewChatFlow.module.scss';
import { decode } from '../../utils/jwt';
import { getCookie } from '../../utils/cookies';
import VideoSelection from '../VideoSelection/VideoSelection';
import Popup from '../Popup/Popup';

const NewChatFlow = ({ detailsByVideo = {} }) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const showVideoSelection = searchParams.get('videoSelection') === 'true';

  const jwtToken = getCookie('auth');
  const { email = '' } = decode(jwtToken) || {};
  const name = email.split('@')[0];

  const handleClosePopup = () => {
    searchParams.delete('videoSelection');
    setSearchParams(searchParams);
  }

  return (
    <div className={styles.container}>
      <Message message={{ role: 'assistant', content: `Welcome ${name}, Do you have a question about a specific video, or a general question?\n1. **[Specific Video](${location.pathname}?videoSelection=true)**\n2. **[General Question](/chat)**\n\n **Click one of the options above**` }} />
      {showVideoSelection && (
        <Popup onClose={handleClosePopup}>
          <VideoSelection detailsByVideo={detailsByVideo} />
        </Popup>
      )}
    </div>
  )
}

export default NewChatFlow;
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { askQuestion, getVideoDetails } from '../../api';
import MessagesList from '../../components/MessagesList/MessagesList';
import QuestionField from '../../components/QuestionField/QuestionField';
import ChatEmptyState from '../../components/ChatEmptyState/ChatEmptyState';
import styles from './Chat.module.scss';
import { deleteCookie } from '../../utils/cookies';
import localStorageUtils from '../../utils/localStorage';
import NewChatFlow from '../../components/NewChatFlow/NewChatFlow';
import Sidebar from '../../components/Sidebar/Sidebar';
import Toast from '../../components/Toast/Toast';
import UserSettings from '../../components/UserSettings/UserSettings';

const CHAT_HISTORY_STORAGE_KEY = 'chatHistory';

const Chat = ({ isNewChat = false }) => {
    const { videoId } = useParams();
    const chatHistoryStorage = localStorageUtils.getItem(CHAT_HISTORY_STORAGE_KEY) || {};
    const chatHistory = videoId ? chatHistoryStorage[videoId] : chatHistoryStorage.General;
    const initialMessages = chatHistory || [];
    const [messages, setMessages] = useState(initialMessages);
    const [isLoading, setIsLoading] = useState(false);
    const [toastError, setToastError] = useState(null);
    const toastErrorTimer = useRef();
    const [detailsByVideo, setDetailsByVideo] = useState({});
    const videoTitle = detailsByVideo[videoId]?.snippet?.title;
    const messagesAreaRef = useRef();
    const navigate = useNavigate();


    const sendQuestion = async (question) => {
        const newMessage = {
            role: 'user',
            content: question
        };

        const newMessages = [...messages, newMessage];

        setMessages(newMessages);
        setIsLoading(true);

        try {
            const res = await askQuestion({
                question,
                chatHistory: messages,
                videoId,
            });
            setIsLoading(false);

            if (res.error) {
                // removes last sent message
                setMessages(messages);

                if (res.error.name === "JsonWebTokenError" || res.error.name === 'TokenExpiredError') {
                    deleteCookie('auth');
                    return navigate('/login');
                }

                if (res.error.name === "PayloadTooLargeError") {
                    if (toastErrorTimer.current) {
                        clearTimeout(toastErrorTimer.current);
                    }

                    setToastError({
                        title: "Chat History Too Long",
                        description: "Please clear your chat history to begin a new chat",
                    });

                    toastErrorTimer.current = setTimeout(() => {
                        setToastError(null);
                    }, 5000);
                }
                return;
            }

            const responseMessage = {
                role: 'assistant',
                content: res.content.answer,
                links: res.content.context,
            }

            setMessages([...newMessages, responseMessage]);
        } catch (e) {
            // removes last sent message
            setMessages(messages);
            setIsLoading(false);
        }
    }

    const clearChatHistory = () => {
        // triggers the useEffect that removes the chatHistory
        setMessages([]);
    }

    useEffect(() => {
        const chatHistoryStorage = localStorageUtils.getItem(CHAT_HISTORY_STORAGE_KEY) || {};
        const chatHistory = videoId ? chatHistoryStorage[videoId] : chatHistoryStorage.General;
        const initialMessages = chatHistory || [];
        setMessages(initialMessages);

        (async () => {
            const detailsByVideo = await getVideoDetails();
            if (videoId && !detailsByVideo[videoId]) {
                return navigate('/chat');
            }
            setDetailsByVideo(detailsByVideo);
        })();

    }, [videoId]);

    useEffect(() => {
        messagesAreaRef.current.scrollTo(0, messagesAreaRef.current.scrollHeight)
    }, [isLoading, videoId])

    useEffect(() => {
        const chatHistoryKey = videoId || 'General';
        const newChatHistory = {
            ...(localStorageUtils.getItem(CHAT_HISTORY_STORAGE_KEY) || {}),
            [chatHistoryKey]: messages
        };
        localStorageUtils.setItem(CHAT_HISTORY_STORAGE_KEY, newChatHistory);
    }, [messages, videoId]);

    return (
        <div className={styles.pageContainer} key={`chat-window-${videoId || 'General'}`}>
            {toastError && (
                <Toast title={toastError.title} description={toastError.description} />
            )}
            <Sidebar chatHistoryStorage={chatHistoryStorage} detailsByVideo={detailsByVideo} isLoadingMessage={isLoading} />
            <div className={styles.userSettingsWrapper}>
                <UserSettings />
            </div>
            <div className={styles.chatContainer}>
                <div className={styles.chatBody}>
                    <div className={styles.messagesContainer} ref={messagesAreaRef}>
                        {isNewChat && (
                            <NewChatFlow detailsByVideo={detailsByVideo} />
                        )}
                        {!isNewChat && (
                            !messages?.length
                                ? <ChatEmptyState videoTitle={videoTitle} />
                                : <MessagesList messages={messages} isLoading={isLoading} />
                        )}

                    </div>
                    <QuestionField
                        onSendQuestion={sendQuestion}
                        onClearChatHistory={clearChatHistory}
                        disabled={isNewChat}
                        disableSubmit={isLoading}
                    />
                </div>
            </div>
        </div>
    );
}

export default Chat;

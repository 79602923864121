import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Fuse from 'fuse.js'
import styles from './VideoSelection.module.scss';
import Input from '../Input/Input';

const VideoSelection = ({ detailsByVideo }) => {
  const [videoIds, setVideoIds] = useState([]);
  const fuseOptions = {
    minMatchCharLength: 2,
    threshold: 0.3,
    keys: [
      "snippet.title",
    ]
  };

  const fuse = new Fuse(Object.values(detailsByVideo), fuseOptions);
  const sortVideoIds = (videoIds) =>
    videoIds.sort((id1, id2) => new Date(detailsByVideo[id2].snippet.publishedAt) - new Date(detailsByVideo[id1].snippet.publishedAt))

  useEffect(() => {
    setVideoIds(sortVideoIds(Object.keys(detailsByVideo)));
  }, [detailsByVideo]);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    const searchResults = fuse.search(searchTerm);
    if (searchResults?.length) {
      setVideoIds(searchResults.map(({ item }) => item.id));
    } else {
      setVideoIds(sortVideoIds(Object.keys(detailsByVideo)));
    }
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Select a video</h1>
      <div className={styles.searchContainer}>
        <Input placeholder="Search for video by title" onChange={handleSearchChange} />
      </div>
      <div className={styles.selectionList}>
        {videoIds.map(videoId => (
          <Link to={`/chat/${videoId}`} key={videoId} className={styles.selection}>
            <div className={styles.selectionBackground} style={{ backgroundImage: `url(${detailsByVideo[videoId]?.snippet?.thumbnails.standard.url})` }} />
            <div className={styles.selectionLabel}>{detailsByVideo[videoId]?.snippet?.title}</div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default VideoSelection;